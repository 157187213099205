import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import {
   Divider,
   Descriptions,
   Tag,
   Row,
   Col,
   Button,
   Space,
   Rate,
   Tabs,
   Steps,
   Typography,
   Empty
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Title } from '@shared/ui';
import { StatusTag } from '@shared/ui';
import { statusesOfLeads } from '@shared/const/statuses';
import {
   ArrowLeftOutlined,
   CheckOutlined,
   CheckCircleOutlined
} from '@ant-design/icons';
import SimpleLeadInfo from '@features/list-lead/ui/SimpleLeadInfo';
import ChatOfLead from '@widgets/Chat/ChatOfLead';
import RatingOfEntity from '@features/list-rating/ui/RatingOfEntity';
import Notes from '@features/notes/ui/index';
import TableLeadCategories from '@features/list-lead/ui/TableLeadCategories';
import LeadTimesList from '@shared/ui/LeadTimesList';
import { addressExtraFields } from '@shared/const/leadExtraFields';
import {
   StatusesDateTimeLine,
   StatusesDateTimeLineXronology
} from '@widgets/Table/StatusesDateTimeLine';
import DriverSimpleDescriptions from '@features/list-driver/ui/DriverSimpleDescriptions';

import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import CanDo from '@shared/lib/CanDo';

import { VerticalSpace } from '@shared/ui';
import {
   ReloadOutlined,
   EditOutlined,
   StopOutlined,
   ClockCircleOutlined
} from '@ant-design/icons';
import ModalLeadForm from '@features/list-lead/ui/ModalLeadForm';
import stringToColor from '@shared/utils/stringToColor';
import OnlyViewPicturesWall from '@widgets/PicturesWall/OnlyViewPicturesWall';
import { deleteLeadById } from '@shared/api/all/lead';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import { GetLead } from '../model/GetLead';
import { UpdateStatusOfLead } from '@features/list-lead/model/UpdateStatusOfLead';
import SellerSimpleDescriptions from '@features/list-seller/ui/SellerSimpleDescriptions';
import ModalButtonLeadCopy from './ModalButtonLeadCopy';
import ModalButtonRatingCreateToEntityWithSMS from '@features/list-rating/ui/ModalButtonRatingCreateToEntityWithSMS';
import { unitSettings } from '@shared/const/units';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { leadExtraFieldsToExport } from '@shared/const/leadExtraFields';
import ManageManagerOfSeller from '@features/list-seller/ui/ManageManagerOfSeller';
import CanDoManager from '@shared/lib/CanDoManager';
import UserCardById from '@features/list-user/ui/UserCardById';
import SimpleRouteInfo from '@features/list-route/SimpleRouteInfo';

const { Text } = Typography;

const createdForm = {
   erp: 'из админки',
   public: 'публичный',
   mobileSellerApp: 'из мобильного приложения продавца'
};

const leadDescriptionLabelsAndValues = [
   { label: 'Адрес', key: 'address', render: (_) => _?.address },
   {
      label: 'Район',
      key: 'address',
      render: (_) => _?.districtName || 'Не найден'
   },
   // {
   //    label: 'Вес',
   //    key: 'capacity',
   //    render: (_, record) =>
   //       _ ? `${_} ${suffix(record.materialCategory.unit)}` : `Не указан вес`
   // },
   // {
   //    label: 'Стоимость',
   //    key: 'price',
   //    render: (_, record) => (
   //       <Space size="small" direction="vertical" wrap>
   //          {_
   //             ? `${_} ${record.priceType === 'unit' ? 'руб/кг' : 'руб'}`
   //             : 'Бесплатно'}

   //          {_ && record.priceType === 'unit' ? (
   //             <Tag bordered={false}>
   //                <Text type="secondary" style={{ fontSize: '12px' }}>
   //                   {_
   //                      ? `Общая: ${`${record?.finalPrice} руб` || 'неизвестно'}`
   //                      : ''}
   //                </Text>
   //             </Tag>
   //          ) : null}
   //       </Space>
   //    )
   // },
   {
      label: 'Откуда заявка',
      key: 'createdFrom',
      render: (_) => createdForm[_]
   }
   // {
   //    label: 'Категория сырья',
   //    key: 'materialCategory',
   //    render: (_) => _?.name && <Tag color={stringToColor(_.name)}>{_.name}</Tag>
   // }
   // {
   //    label: 'Дата',
   //    key: 'createdAt',
   //    render: (_, record) => (

   //    )
   // }
   // {
   //    label: 'Дата завершения',
   //    key: 'createdAt',
   //    render: (_) => <DateWithAgo date={_} />
   // }
];

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const LeadDescription = ({ id }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [modalShow, setModalShow] = useState(null);

   const fetchData = () => {
      setLoading(true);
      GetLead(id).then((res) => {
         setData({ ...res });

         setLoading(false);
      });
   };

   useEffect(() => {
      fetchData();
      const intervalId = setInterval(() => {
         fetchData();
      }, 102000);

      return () => {
         clearInterval(intervalId);
      };
   }, [id]);

   const closeModal = () => {
      fetchData();
      setModalShow(null);
   };

   // const updateLeadStatusRequest = (leadId) => {
   //    UpdateStatusOfLead({ status: 'blocked' }, leadId).then(() => fetchData());
   // };

   const update = () => {
      fetchData();
   };

   // if (loading) {
   //    return (
   //       <Space
   //          style={{
   //             display: 'flex',
   //             justifyContent: 'center',
   //             alignItems: 'center',
   //             height: '300px',
   //             width: '100%'
   //          }}>
   //          <Spin />
   //       </Space>
   //    );
   // }

   const getStatusOfStep = (curStat, index) => {
      if (
         index === data?.leadStatuses?.length - 1 &&
         curStat !== 'finished' &&
         curStat !== 'blocked'
      ) {
         return 'process';
      }

      if (curStat === 'finished') {
         return 'finish';
      }

      if (curStat === 'active') {
         return 'wait';
      }

      if (curStat === 'blocked') {
         return 'error';
      }

      if (curStat === 'inwork') {
         return 'active';
      }

      return 'process';
   };

   const driverId = data?.driver?.id;
   const leadId = data?.id;
   const sellerId = data?.address?.seller?.id;

   return (
      <>
         <div style={{ margin: '25px 0' }}>
            <Space size="large" align="center" wrap>
               <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}></Button>
               <Title
                  style={{
                     padding: 0,
                     margin: 0
                  }}>{`Заявка №${id}`}</Title>
               <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                  <NewEntityTag date={data?.createdAt} isBig={true} />{' '}
                  <StatusTag status={data.status} type="lead" />
               </div>
            </Space>
         </div>

         <ModalLeadForm selectedLead={modalShow} closeModal={closeModal} />
         <Row gutter={24}>
            <Col span={24}>
               <Row gutter={24}>
                  <Space size="middle" style={{ marginLeft: '10px' }}>
                     <Button
                        onClick={() => update()}
                        icon={<ReloadOutlined />}
                        loading={loading}>
                        Обновить
                     </Button>

                     <CanDo permission="can_edit_leads">
                        <CanDoManager
                           userId={data.userId}
                           alertMessage="Вам нельзя редактировать, так как ответственен другой человек.">
                           <Button
                              onClick={() => setModalShow(data)}
                              type="primary"
                              icon={<EditOutlined />}>
                              Редактировать
                           </Button>{' '}
                        </CanDoManager>
                     </CanDo>

                     {data.status === 'finished' && !data?.rating?.id && (
                        <ModalButtonRatingCreateToEntityWithSMS
                           leadId={data.id}
                           buttonText={
                              data?.rating?.id
                                 ? 'Изменить оценку'
                                 : 'Добавить оценку'
                           }
                           id={data?.rating?.id || null}
                           isSendedSmsRating={data.isSendedSmsRating === 1}
                           valuesOfRating={{
                              actionForEntityType: 'lead',
                              actionForEntityId: data?.id,
                              fromEntityType: 'seller',
                              fromEntityId: data?.address?.seller?.id,
                              toEntityType: 'driver',
                              toEntityId: data?.driver?.id,
                              status: 'active',
                              categoryOfRating: 1
                           }}
                           closeModal={fetchData}
                        />
                     )}

                     <ManageManagerOfSeller
                        type="lead"
                        data={data}
                        callback={fetchData}
                     />

                     <ModalButtonLeadCopy closeModal={closeModal} id={data.id} />

                     <CanDo permission="can_edit_leads">
                        {/* {data.status === 'active' && (
                           <Button
                              type="primary"
                              danger
                              icon={<StopOutlined />}
                              onClick={() => updateLeadStatusRequest(data.id)}>
                              Заблокировать
                           </Button>
                        )} */}

                        {/* {data.status === 'blocked' && (
                           <DeleteEntityButton
                              id={data.id}
                              buttonText="Удалить"
                              callbackFetch={deleteLeadById}
                              update={() => navigate('/leads')}
                              permission="can_delete_leads"
                           />
                        )} */}
                     </CanDo>
                  </Space>
               </Row>
            </Col>
            <Col span={24}>
               <Row gutter={24}>
                  <Col span={14}>
                     <Row gutter={24}>
                        <Col span={12} style={{ minWidth: '200px' }}>
                           <Divider orientation="left">Информация о заявке</Divider>
                           <SimpleLeadInfo data={data} />

                           {data?.entityCategories?.length > 0 ? (
                              <>
                                 <Divider orientation="left">Сырье</Divider>
                                 <TableLeadCategories
                                    data={data?.entityCategories}
                                 />
                              </>
                           ) : null}

                           {/* <Descriptions size="small">
                              {leadDescriptionLabelsAndValues.map((e) => (
                                 <Descriptions.Item
                                    span={3}
                                    key={`${data.id}-${e.label}`}
                                    label={e.label}>
                                    {e.render(data?.[e.key], data)}
                                 </Descriptions.Item>
                              ))}
                           </Descriptions> */}
                           {data.comment ? (
                              <>
                                 <Divider orientation="left">
                                    Комментарий к заявке
                                 </Divider>
                                 <div
                                    className="description-comment-block"
                                    style={{ whiteSpace: 'pre-wrap' }}>
                                    {data.comment ? (
                                       data.comment
                                    ) : (
                                       <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    )}
                                 </div>
                              </>
                           ) : null}

                           {/* <div
                              className="description-comment-block"
                              style={{ whiteSpace: 'pre-wrap' }}>
                              {data?.rating?.id ? (
                                 <>
                                    <Row gutter={24}>
                                       <Col span={24}>
                                          <Rate
                                             className="rate-big"
                                             allowHalf
                                             disabled={true}
                                             allowClear={true}
                                             defaultValue={0}
                                             value={data?.rating?.value / 10}
                                          />
                                       </Col>

                                       <Col span={24} style={{ marginTop: '15px' }}>
                                          Комментарий к оценке:{' '}
                                          {data?.rating?.comment ||
                                             'Без комментариев'}
                                       </Col>
                                    </Row>
                                    <VerticalSpace />
                                 </>
                              ) : (
                                 <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                              )}
                           </div> */}

                           {data?.address?.seller?.id && (
                              <>
                                 <Divider orientation="left">
                                    Заявка от продавца
                                 </Divider>{' '}
                                 <SellerSimpleDescriptions
                                    data={data?.address?.seller}
                                 />
                              </>
                           )}

                           <Divider orientation="left">Адрес на карте</Divider>
                           {data?.address && (
                              <YMaps>
                                 <div className="map-card">
                                    <Map
                                       defaultState={{
                                          center: [
                                             data?.address?.geoLat,
                                             data?.address?.geoLon
                                          ],
                                          zoom: 15
                                       }}
                                       height={'240px'}
                                       width={'100%'}>
                                       <Placemark
                                          defaultGeometry={[
                                             data?.address?.geoLat,
                                             data?.address?.geoLon
                                          ]}
                                       />
                                    </Map>
                                 </div>
                              </YMaps>
                           )}
                        </Col>
                        <Col span={10} style={{ minWidth: '200px' }}>
                           {data?.userId ? (
                              <>
                                 <Divider orientation="left">Ответственный</Divider>
                                 <UserCardById id={data?.userId} />{' '}
                              </>
                           ) : null}
                           <Divider orientation="left">
                              Требования к вывозу от заявки
                           </Divider>
                           <div className="list-of-extra">
                              {Object.values(leadExtraFieldsToExport).map((e) => (
                                 <React.Fragment key={e.value + e.label}>
                                    {data?.[e.value] !== null &&
                                    data?.[e.value] === true ? (
                                       <div
                                          className={`list-of-extra_card list-of-extra_card_${
                                             !!data?.[e.value] ? 'yes' : 'no'
                                          }`}>
                                          <CheckCircleOutlined
                                             style={{
                                                marginRight: '7px',
                                                color: 'green',
                                                fontSize: '16px'
                                             }}
                                          />
                                          <Text>{e.label}</Text>{' '}
                                          {/* <Text strong>
                                             {e.type === 'boolean'
                                                ? !!data?.[e.value]
                                                   ? 'Да'
                                                   : 'Нет'
                                                : null}

                                             {e.type === 'integer'
                                                ? data?.[e.value]
                                                : null}
                                          </Text> */}
                                       </div>
                                    ) : null}
                                 </React.Fragment>
                              ))}
                           </div>
                           <Divider orientation="left">
                              Требования к вывозу от адресата
                           </Divider>
                           <div className="list-of-extra">
                              {Object.values(addressExtraFields).map((e) => (
                                 <React.Fragment key={e.value + e.label}>
                                    {data.address?.[e.value] !== null &&
                                    (data.address?.[e.value] === true ||
                                       data.address?.[e.value] > 0) ? (
                                       <div
                                          className={`list-of-extra_card list-of-extra_card_${
                                             !!data.address?.[e.value] ? 'yes' : 'no'
                                          }`}>
                                          <CheckCircleOutlined
                                             style={{
                                                marginRight: '7px',
                                                color: 'green',
                                                fontSize: '16px'
                                             }}
                                          />
                                          <Text>
                                             {e.label}
                                             {data.address?.[e.value] > 0 &&
                                             e.type === 'integer' ? (
                                                <span>
                                                   {' '}
                                                   : {data.address?.[e.value]} метра
                                                </span>
                                             ) : null}
                                          </Text>
                                       </div>
                                    ) : null}
                                 </React.Fragment>
                              ))}
                           </div>
                           <Divider orientation="left">Даты вывоза</Divider>
                           <div>
                              <LeadTimesList
                                 data={data?.leadsTimes}
                                 showAllEnabled={true}
                              />
                           </div>
                           {data?.driver?.id && (
                              <>
                                 <Divider orientation="left">
                                    Назначенный водитель
                                 </Divider>{' '}
                                 <DriverSimpleDescriptions data={data?.driver} />
                              </>
                           )}
                           {/* <div style={{ paddingTop: '15px' }}>
                              <Timeline
                                 mode="right"
                                 items={[
                                    {
                                       color: data?.createdAt ? 'green' : 'grey',
                                       label: 'Создание',
                                       children: (
                                          <>
                                             <DateWithAgo date={data?.createdAt} />
                                          </>
                                       )
                                    },
                                    {
                                       color: data?.e2xportDate ? 'green' : 'grey',
                                       label: 'Вывоз',
                                       children: (
                                          <>
                                             {data?.e2portDate ? (
                                                <DateWithAgo
                                                   date={data.ex2portDate}
                                                />
                                             ) : (
                                                ' не указан'
                                             )}
                                          </>
                                       )
                                    },
                                    {
                                       color: data?.finishDate ? 'green' : 'grey',
                                       label: 'Завершение',
                                       children: (
                                          <>
                                             {data?.finishDate ? (
                                                <DateWithAgo
                                                   date={data.finishDate}
                                                />
                                             ) : (
                                                ' не указан'
                                             )}
                                          </>
                                       )
                                    }
                                 ]}
                              />
                           </div> */}
                           {data?.images?.length > 0 ? (
                              <>
                                 {' '}
                                 <Divider orientation="left">
                                    Загруженные фото
                                 </Divider>{' '}
                                 <OnlyViewPicturesWall
                                    fileList={data.images}
                                    maxShow={100}
                                 />
                              </>
                           ) : null}
                           {data?.temporaryImages?.length > 0 ? (
                              <>
                                 {' '}
                                 <Divider orientation="left">
                                    Загруженные фото от водителя
                                 </Divider>{' '}
                                 <OnlyViewPicturesWall
                                    fileList={data.temporaryImages}
                                    maxShow={100}
                                 />
                              </>
                           ) : null}
                           <Divider orientation="left">История статусов</Divider>{' '}
                           <div className="big-timeline">
                              <StatusesDateTimeLine
                                 createdAt={data?.createdAt}
                                 updatedAt={data?.updatedAt}
                                 entityId={data?.id}
                                 entityType="lead"
                                 isReverse={false}
                                 isListMode={true}
                              />
                           </div>
                        </Col>{' '}
                     </Row>
                  </Col>

                  <Col span={6}>
                     {driverId ? (
                        <>
                           {' '}
                           <Divider orientation="left">Оценка заявке</Divider>
                           <Divider orientation="center" plain>
                              Продавец
                           </Divider>
                           <div
                              className="description-comment-block"
                              style={{ whiteSpace: 'pre-wrap' }}>
                              <RatingOfEntity
                                 actionForEntityType="lead"
                                 actionForEntityId={leadId}
                                 fromEntityType={'seller'}
                                 fromEntityId={sellerId}
                                 toEntityType={'driver'}
                                 toEntityId={driverId}
                                 status={'active'}
                                 categoryOfRating={1}
                              />
                           </div>
                           <Divider orientation="center" plain>
                              Водитель
                           </Divider>
                           <div
                              className="description-comment-block"
                              style={{ whiteSpace: 'pre-wrap' }}>
                              <RatingOfEntity
                                 actionForEntityType="lead"
                                 actionForEntityId={leadId}
                                 fromEntityType={'driver'}
                                 fromEntityId={driverId}
                                 toEntityType={'seller'}
                                 toEntityId={sellerId}
                                 status={'active'}
                                 categoryOfRating={1}
                              />
                           </div>
                        </>
                     ) : null}

                     <Notes entityId={id} entityType="lead" />
                  </Col>

                  {/* <Col span={8}>
                     <Divider orientation="left">Информация о маршруте</Divider>
                     <SimpleRouteInfo routeId={data?.routeId} />

                     <Divider orientation="left">
                        Чат между водителем и продавцом
                     </Divider>

                     {data?.route?.driver?.id && data?.address?.seller ? (
                        <ChatOfLead
                           leadId={data.id}
                           driver={data?.route?.driver}
                           seller={data?.address?.seller}
                        />
                     ) : (
                        `Не назначен водитель`
                     )}

                     <Notes entityId={id} entityType="lead" />
                  </Col> */}

                  {/* <Col span={11}>
                     {' '}
                     <Tabs
                        tabPosition={'top'}
                        defaultActiveKey="1"
                        type="card"
                        items={[
                           {
                              label: 'История',
                              key: '1',
                              children: 'В разработке'
                           },
                           {
                              label: 'Связи заявки',
                              key: '2',
                              children: 'В разработке'
                           }
                        ]}
                     />
                  </Col> */}
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default LeadDescription;
