import React, { useEffect } from 'react';
import {
   CarOutlined,
   SendOutlined,
   MobileOutlined,
   AppstoreOutlined,
   ShareAltOutlined,
   FileTextOutlined,
   ShopOutlined,
   ProjectOutlined,
   UsergroupAddOutlined,
   DashboardOutlined,
   StarOutlined,
   AlignLeftOutlined,
   UnorderedListOutlined,
   MessageOutlined
} from '@ant-design/icons';
import { Menu, Layout, Divider, Badge } from 'antd';
import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutePath, routeList } from '@shared/config/routes';
import { SIDEBAR_LOCALSTORAGE_KEY } from '@shared/const/localStorage';
import { MenuProfile } from '@shared/ui/MenuProfile';
import { useSelector } from 'react-redux';
import { getUserPermissions } from '@entitles/User';
import NotificationDrawer from '@widgets/Notifications';
import { unreadChatCount } from '@shared/api/all/chat';
import ModalButtonDriversMap from '@features/list-driver/ui/ModalButtonDriversMap';
import ModalButtonLeadsMap from '@features/list-lead/ui/ModalButtonLeadsMap';
import {
   getLocalStorageByKey,
   setLocalStorageByKey
} from '@shared/lib/localStorage';

const { Footer, Sider, Content, Header } = Layout;

function getItem(label, key, icon, children, disabled) {
   return {
      key,
      icon,
      children,
      label,
      disabled
   };
}
const allMenuItems = (unreadSupportCount = 0) => [
   getItem('Панель', 'dashboard', <DashboardOutlined />),
   getItem('Пользователи', 'users', <UsergroupAddOutlined />),
   getItem('Журнал действий', 'logs', <AlignLeftOutlined />),
   getItem(
      <>
         Поддержка{' '}
         <Badge
            count={unreadSupportCount}
            style={{
               marginLeft: '5px',
               backgroundColor: '#52c41a'
            }}
         />
      </>,
      'support',
      <MessageOutlined />
   ),
   getItem(
      '',
      '',
      <Divider orientation="left" plain className="menu-divider">
         Логистика
      </Divider>,
      '',
      true
   ),
   getItem('Заявки', 'leads', <AppstoreOutlined />),
   // getItem('Маршруты', 'routes', <ShareAltOutlined />),
   // getItem('Торги', 'auctions', <ProjectOutlined />),
   getItem(
      '',
      '',
      <Divider orientation="left" plain className="menu-divider">
         Клиенты
      </Divider>,
      '',
      true
   ),
   getItem('Водители', 'drivers', <CarOutlined />),
   getItem('Продавцы', 'sellers', <SendOutlined />),
   getItem('Приемки', 'acceptances', <ShopOutlined />),
   getItem(
      '',
      '',
      <Divider orientation="left" plain className="menu-divider">
         Прочее
      </Divider>,
      '',
      true
   ),
   getItem('Категории сырья', 'materialcategories', <UnorderedListOutlined />),
   getItem('Рейтинги', 'ratings', <StarOutlined />),
   getItem('Контент', 'content', <FileTextOutlined />),
   getItem('Моб. Приложения', 'mobile-apps', <MobileOutlined />)
];

const DashboardLayout = (props) => {
   const navigate = useNavigate();
   const location = useLocation();
   const [collapsed, setCollapsed] = useState(false);
   const permissions = useSelector(getUserPermissions);

   const [unreadSupportCount, setUnreadSupportCount] = useState(0);

   useEffect(() => {
      getLocalStorageByKey(SIDEBAR_LOCALSTORAGE_KEY).then((res) => {
         if (res) {
            setCollapsed(res === 'true');
         }
      });
      getUnreadSupportCount();
   }, []);

   const getUnreadSupportCount = () => {
      unreadChatCount(
         {
            entityToType: 'user',
            entityToId: '0'
         },
         'support',
         '0'
      ).then((res) => {
         setUnreadSupportCount(res?.json?.data || 0);

         setTimeout(() => {
            getUnreadSupportCount();
         }, 5000);
      });
   };

   const onCollapse = (value) => {
      setCollapsed(value);
      setLocalStorageByKey(SIDEBAR_LOCALSTORAGE_KEY, value);
   };

   const onClick = (e) => {
      const routeName = e?.key;
      if (!routeName || !RoutePath[routeName]) return;

      navigate(RoutePath[routeName]);
   };

   let pathName = location.pathname.replace('/', '');

   if (pathName.includes('/')) {
      pathName = pathName.split('/')[0];
   }

   const selectedRoute = RoutePath[pathName] ? [pathName] : null;

   const actualItems = allMenuItems(unreadSupportCount).filter((item) =>
      // Думаю в будущем придется и вложенные категории также фильтровать
      routeList[item?.key]?.permission
         ? permissions.includes(routeList[item?.key]?.permission)
         : true
   );

   return (
      <Layout
         style={{
            minHeight: '100vh'
         }}>
         <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            trigger={false}
            className="left-sider-menu">
            <div className="left-side-logo" onClick={() => navigate('/dashboard')} />
            <Menu
               onClick={onClick}
               mode="inline"
               items={actualItems}
               className="menu"
               selectedKeys={selectedRoute}
               defaultSelectedKeys={selectedRoute}
            />
         </Sider>
         <Layout>
            <Header className="erp-header">
               <div />

               <div style={{ display: 'flex' }}>
                  <div
                     style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                     {' '}
                     <ModalButtonDriversMap />
                     <ModalButtonLeadsMap />
                     <NotificationDrawer />
                  </div>
                  <MenuProfile />
               </div>
            </Header>
            <Content
               style={{
                  margin: '0 16px'
               }}>
               <div
                  style={{
                     padding: '0 24px',
                     minHeight: 360
                  }}>
                  {props.children}
               </div>
            </Content>
            <Footer
               style={{
                  textAlign: 'center'
               }}>
               © 2022 – {new Date().getFullYear()} Ecorium. Все права защищены.
            </Footer>
         </Layout>
      </Layout>
   );
};
export default DashboardLayout;
