const extraFields = {
   isLoadingRequired: {
      label: 'Нужна погрузка',
      value: 'isLoadingRequired',
      type: 'boolean',
      isAddressField: false
   },
   isThereLift: {
      label: 'Есть подъемные механизмы (лифт/тележка)',
      value: 'isThereLift',
      type: 'boolean',
      isAddressField: true
   },
   isPassRequired: {
      label: 'Нужен пропуск на машину',
      value: 'isPassRequired',
      type: 'boolean',
      isAddressField: true
   },
   areScalesRequired: {
      label: 'Нужны весы',
      value: 'areScalesRequired',
      type: 'boolean',
      isAddressField: false
   },
   areThereRestrictionsSize: {
      label: 'Есть ограничения по габаритам машины (арка/проезд/подземный гараж)',
      value: 'areThereRestrictionsSize',
      type: 'boolean',
      isAddressField: true
   },
   isNeededPassport: {
      label: 'Нужен паспорт РФ для вывоза',
      value: 'isNeededPassport',
      type: 'boolean',
      isAddressField: true
   },
   distance: {
      label: 'Расстояние от места сырья до зоны погрузки в метрах',
      value: 'distance',
      type: 'integer',
      isAddressField: true
   }
};

const addressExtraFields = Object.values(extraFields).filter(
   (field) => field.isAddressField
);

const leadExtraFieldsToExport = Object.values(extraFields).filter(
   (field) => !field.isAddressField
);

export { leadExtraFieldsToExport, addressExtraFields };
