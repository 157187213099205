import React from 'react';
import { Tooltip, Table, Typography } from 'antd';
import TooltipTextWithEllipsis from '@shared/ui/Tooltips/TooltipTextWithEllipsis';
import CategoryIcon from '@features/manage-materialcategories/ui/CategoryIcon';

const { Text } = Typography;

const TableLeadCategories = ({ data }) => {
   const columns = [
      {
         title: 'Категория',
         dataIndex: 'materialCategory',
         key: 'materialCategory',
         width: '190px',
         render: (_, record) => (
            <div className="table-category-iconname">
               <CategoryIcon icon={_.icon} />
               <TooltipTextWithEllipsis text={_.name} maxLength={20} />
            </div>
         )
      },
      {
         title: 'Вес',
         dataIndex: 'capacity',
         key: 'capacity',
         align: 'right',
         width: '80px',
         render: (text) => (
            <Text>
               {text} {'кг'}
            </Text>
         )
      },
      {
         title: 'Цена',
         dataIndex: 'price',
         key: 'price',
         align: 'right',
         width: '80px',
         render: (text) => (
            <Text>
               {text} {'руб'}
            </Text>
         )
      }
   ];

   if (data && data?.length === 0) {
      return null;
   }

   return (
      <div style={{ padding: '0px' }}>
         <Table
            className="table-lead-categories"
            dataSource={data}
            showHeader={false}
            columns={columns}
            pagination={false}
            size="small"
            bordered={true}
            rowHoverable={false}
            summary={(pageData) => {
               let totalPrice = 0;
               let totalCapacity = 0;
               pageData.forEach(({ price, capacity }) => {
                  totalPrice += price;
                  totalCapacity += capacity;
               });
               return (
                  <>
                     <Table.Summary.Row>
                        <Table.Summary.Cell index={0}>Общее</Table.Summary.Cell>
                        <Table.Summary.Cell index={1} align="right">
                           <Text type="danger">
                              {totalCapacity} {'кг'}
                           </Text>
                        </Table.Summary.Cell>
                        <Table.Summary.Cell index={2} align="right">
                           <Text type="success">
                              {totalPrice} {'руб'}
                           </Text>
                        </Table.Summary.Cell>
                     </Table.Summary.Row>
                  </>
               );
            }}
         />
      </div>
   );
};

export default TableLeadCategories;
